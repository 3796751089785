const en = {
  hi: `Hi`,
  documentation: `documentation`,
  app: `app`,
  user: `user`,
  edit: `edit`,
  chat: `chat`,
  create: `create`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  management: `management`,
  description: `description`,
};

export default en;
