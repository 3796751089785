import { Box } from '@mui/material';
import { NavSectionHorizontal } from 'components/nav-section';
import PropTypes from 'prop-types';
import { memo } from 'react';
import navConfig from './config-navigation';

function NavHorizontal() {
  return <NavSectionHorizontal data={navConfig} />;
}

export default memo(NavHorizontal);

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
