//
import Fab from './Fab';
import Card from './Card';
import Chip from './Chip';
import Menu from './Menu';
import Link from './Link';
import Lists from './List';
import Table from './Table';
import Paper from './Paper';
import Drawer from './Drawer';
import Slider from './Slider';
import Button from './Button';
import Select from './Select';
import SvgIcon from './SvgIcon';
import Skeleton from './Skeleton';
import Progress from './Progress';
import Typography from './Typography';
import ButtonGroup from './ButtonGroup';
import ControlLabel from './ControlLabel';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Fab(theme),
    Chip(theme),
    Card(theme),
    Menu(theme),
    Link(theme),
    Lists(theme),
    Table(theme),
    Paper(theme),
    Select(theme),
    Button(theme),
    Slider(theme),
    Drawer(theme),
    SvgIcon(theme),
    Skeleton(theme),
    Progress(theme),
    Typography(theme),
    ButtonGroup(theme),
    ControlLabel(theme)
  );
}
