import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from 'components/iconify';
import Logo from 'components/logo';
import { useSettingsContext } from 'components/settings';
import { HEADER } from 'config-global';
import useOffSetTop from 'hooks/useOffSetTop';
import useResponsive from 'hooks/useResponsive';
import PropTypes from 'prop-types';
import { bgBlur } from 'utils/cssStyles';
import NavHorizontal from '../nav/NavHorizontal';
import CustomizedSwitche from './ThemeSwitch';

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { themeLayout, themeMode, onChangeMode } = useSettingsContext();
  const isNavHorizontal = themeLayout === 'horizontal';
  const isDesktop = useResponsive('up', 'lg');
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      <Logo sx={{ mr: 2.5 }} />
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <Typography variant="h6" color="text.primary" sx={{ textTransform: 'capitalize' }}>
          Football
        </Typography> */}
        {!!isDesktop && <NavHorizontal />}
        <Box sx={{ flexGrow: 1 }} />
        <CustomizedSwitche themeMode={themeMode} onChangeMode={onChangeMode} />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        borderBottom: `solid 1px ${theme.palette.divider}`,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100%)`,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...{
            width: 1,
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          },
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
