function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/matches';

export const PATH_PAGE = {
  page404: '/404',
  about: '/about-us',
  contact: '/contact-us',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  blogs: '/blogs',
  blogsView: (title) => `/blogs/${title}`,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  matches: {
    allMatches: path(ROOTS_DASHBOARD, '/soccer'),
    football: (teamA, teamB) => path(ROOTS_DASHBOARD, `/soccer/${teamA}/${teamB}`),
  },
};
