const ar = {
  hi: `أهلا`,
  documentation: `توثيق`,
  app: `تطبيق`,
  home: `بيت`,
  'report issue': `قضية تقرير`,
  'order history': `تاريخ الطلب`,
  'help?': `يساعد؟`,
  'book shipment': `شحنة كتاب`,
  user: `المستعمل`,
  settings: `إعدادات`,
  logout: `تسجيل خروج`,
  list: `قائمة`,
  edit: `تعديل`,
  chat: `دردشة`,
  create: `يزيد`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  management: `إدارة`,
  description: `وصف`,
};

export default ar;
